<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.parties") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  :allowCreate="false"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th class="w50p" v-if="columns.custom_id.show">
                    {{ columns.custom_id.title }}
                </th>

                <th v-if="columns.date.show">
                    {{ columns.date.title }}
                </th>

                <th v-if="columns.airway_bill_number.show">
                    {{ columns.airway_bill_number.title }}
                </th>

                <th v-if="columns.from_filial.show">
                    {{ columns.from_filial.title }}
                </th>

                <th v-if="columns.to_filial.show">
                    {{ columns.to_filial.title }}
                </th>

                <th v-if="columns.containersCount.show">
                    {{ columns.containersCount.title }}
                </th>

                <th v-if="columns.total_weight.show">
                    {{ columns.total_weight.title }}
                </th>

                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.custom_id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.custom_id"
                      :placeholder="columns.custom_id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.date.show">
                  <crm-date-picker
                      :placeholder="columns.date.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.date"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.airway_bill_number.show">
                  <crm-input
                      :placeholder="columns.airway_bill_number.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.airway_bill_number"
                  ></crm-input>
              </th>

              <th v-if="columns.from_filial.show">
                <select-from-filial
                    :size="'medium'"
                    :select_only="true"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :placeholder="columns.from_filial.title"
                    v-model="filterForm.from_filial_id"
                >
                </select-from-filial>
              </th>

              <th v-if="columns.to_filial.show">
                <select-to-filial
                    :size="'medium'"
                    :show_all="1"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :placeholder="columns.to_filial.title"
                    v-model="filterForm.to_filial_id"
                >
                </select-to-filial>
              </th>

              <th v-if="columns.containersCount.show">
                  <crm-input
                      :placeholder="columns.containersCount.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.containersCount"
                  ></crm-input>
              </th>

              <th v-if="columns.total_weight.show">
                  <crm-input
                      :placeholder="columns.total_weight.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.total_weight"
                  ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                  <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                  <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                  ></crm-date-picker>
              </th>

              <th
                  class="settinW"
                  v-if="columns.settings.show"
              ></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="party in list" :key="party.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ party.id }}
                </td>

                <td v-if="columns.custom_id.show">
                    {{ party.custom_id }}
                </td>

                <td v-if="columns.airway_bill_number.show">
                    <span v-if="party.airway_bill_numbers && Array.isArray(party.airway_bill_numbers)">
                        {{ party.airway_bill_numbers.join(', ') }}
                    </span>
                </td>
                <td v-if="columns.date.show">
                    {{ party.date }}
                </td>

                <td v-if="columns.from_filial.show">
                    {{ party.from_filial ? party.from_filial.name : '' }}
                </td>

                <td v-if="columns.to_filial.show">
                    {{ party.to_filial ? party.to_filial.name : '' }}
                </td>

                <td v-if="columns.containersCount.show">
                    {{ party.containersCount }}
                </td>

                <td v-if="columns.total_weight.show">
                    {{ party.total_weight | formatNumber(1) }}
                </td>
               
                <td v-if="columns.created_at.show">
                    {{ party.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ party.updated_at }}
                </td>

                <td v-if="columns.settings.show" class="settings-td">
                     
                    <div>
                        <el-dropdown szie="mini" @command="handleCommand">
                            <span class="el-dropdown-link more_icons">
                                <i class="el-icon-arrow-down el-icon-more-outline"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" size="mini">      
                                <el-dropdown-item :command="{type: 'StockReport', party: party}" icon="el-icon-document-delete el-icon--left">       
                                    Stock report NYC
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type: 'Airlines', party: party}" icon="el-icon-document-delete el-icon--left">       
                                    Invoice to airlines
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type: 'Packing', party: party}" icon="el-icon-document-delete el-icon--left">       
                                    Packing List
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";

export default {
    name: "parties",
    mixins: [list],
    components: {
        selectFromFilial,
        selectToFilial
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapGetters({
            list: "parties/list",
            columns: "parties/columns",
            pagination: "parties/pagination",            
            filter: "parties/filter",
            sort: "parties/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "parties/index",
            setPagination: "parties/setPagination",
            updateSort: "parties/updateSort",
            updateFilter: "parties/updateFilter",
            updateColumn: "parties/updateColumn",
            updatePagination: "parties/updatePagination",
            show: "parties/show",
            empty: "parties/empty",
            delete: "parties/destroy",
            refreshData: "parties/refreshData",
            excelFileDownload: "parties/excelFileDownload",
        }),

        handleCommand(command){
            this.downloadExcelFile(command);
        },
        downloadExcelFile({type, party}){
            let name = party.custom_id + '-' + type + '-' + party.from_filial.name + '-' +  party.to_filial.name;
            this.excelFileDownload({type: type, id: party.id})
                .then((res) => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel"
                    })
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob)
                    link.setAttribute("download", (name+'.xlsx'));
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('parties/EMPTY_LIST');
        next()
    },
};
</script>

